import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
    NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
    NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
    showEntireOrgHierarchyForAllUsers = false;
    defaultMonthDateMode: DateMonthModes = 'calendar';
    filterTypeLookup: { [id: string]: FilterType } = {
        'org': { displayName: 'Hierarchy', name: 'org' },
        'brand': { displayName: 'Brand', name: 'brand' },
        'campaign-type': { displayName: 'Campaign Type', name: 'campaign-type' },
        'channel': { displayName: 'Channel', name: 'channel' },
        'device-type': { displayName: 'Device Type', name: 'device-type' },
        'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
        'audience-segment': { displayName: 'AdGroup Audience', name: 'audience-segment' },
        'journey-segment': { displayName: 'AdGroup Journey', name: 'journey-segment' },
        'profit-center': { displayName: 'AdGroup Profit Center', name: 'profit-center' },
        'lma': { displayName: 'LMA', name: 'lma' },
        'lma-region': { displayName: 'LMA Region', name: 'lma-region' },
        'strategy': { displayName: 'Strategy', name: 'strategy'},
        'tactic': { displayName: 'Tactic', name: 'tactic' },
        'vehicle-make': { displayName: 'AdGroup Vehicle Brand', name: 'vehicle-make' },
        'vehicle-makes': { displayName: 'Brand', name: 'vehicle-makes' },
        'vehicle-model': { displayName: 'AdGroup Vehicle Model', name: 'vehicle-model' },
        'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
        'website-provider': { displayName: 'Provider', name: 'website-provider' },
        'website-metric-type': { displayName: 'Metric', name: 'website-metric-type' },
        'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
        'trade-in-provider': { displayName: 'Provider', name: 'trade-in-provider' },
    };

    filterReportConfigs: { [id: string]: FilterConfigReport } = {
        // Website
        'WebsiteOverview': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: false,
            reportName: constants.reportNames.websiteOverview,
            filters: ['org', 'brand', 'channel', 'website-provider', 'device-type'],
            defaults: ['org'],
            section: 'website',
            orgTypes: [1, 2]
        },
        'WebsiteScorecard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.websiteScorecard,
            filters: [
                'org', 'website-provider', 'brand'
            ],
            defaults: [
                'org', 'website-provider'
            ],
            section: 'website',
            orgTypes: [1, 2]
        },
        'WebsiteReferrerSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteReferrerSummary,
            filters: [
                'org', 'device-type', 'brand'
            ],
            defaults: [
                'org', 'device-type'
            ],
            section: 'website',
            orgTypes: [1, 2]
        },
        'WebsiteSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteSummary,
            filters: [
                'org', 'website-provider', 'brand'
            ],
            defaults: [
                'org', 'website-provider'
            ],
            section: 'website',
            orgTypes: [1, 2]
        },
        'WebsiteVehicleSummary': {
            dateMode: Enums.dateModes.currentMonth,
            reportName: constants.reportNames.websiteVehicleSummary,
            filters: [
                'org', 'website-provider', 'brand'
            ],
            defaults: [
                'org', 'website-provider'
            ],
            section: 'website',
            orgTypes: [1, 2]
        },
        // DigAd
        'DigitalAdvertisingDashboard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: false,
            reportName: constants.reportNames.digitalAdvertisingDashboard,
            filters: ['org', 'vehicle-makes', 'channel', 'digad-provider', 'campaign-type'],
            defaults: ['org'],
            section: 'digital-advertising',
            orgTypes: [1, 2]
        },
        'DigitalAdvertisingTier2Dashboard': {
            dateMode: Enums.dateModes.specificMonthOption,
            restrictDates: true,
            reportName: constants.reportNames.digitalAdvertisingTier2Dashboard,
            filters: ['lma','lma-region','tactic','strategy'],
            defaults: [],
            section: 'digital-advertising',
            orgTypes: [1, 2]
        },
        'DigitalAdvertisingProviderScorecard': {
            dateMode: Enums.dateModes.currentMonth,
            restrictDates: true,
            reportName: constants.reportNames.digitalAdvertisingProviderScorecard,
            filters: ['org', 'brand'],
            defaults: ['org'],
            section: 'digital-advertising',
            orgTypes: [1, 2]
        },
        // Trade-In
        'TradeInOverview': {
          reportName: constants.reportNames.tradeInOverview,
          dateMode: Enums.dateModes.currentMonth,
          filters: ['org', 'device-type', 'trade-in-provider', 'brand'],
          defaults: ['org'],
          section: 'provider-tools',
          orgTypes: [1, 2]
        },
        // Chat
        'ChatOverview': {
          dateMode: Enums.dateModes.currentMonth,
          reportName: constants.reportNames.chatOverview,
          filters: ['org', 'device-type', 'chat-provider', 'brand'],
          defaults: ['org'],
          section: 'provider-tools',
          orgTypes: [1, 2]
        },
        'SalesForecastingSummary': {
          dateMode: Enums.dateModes.currentMonth,
          restrictDates: true,
          reportName: constants.reportNames.salesForecastingSummary,
          filters: [
            'org', 'sale-type', 'vehicle-model'
          ],
          defaults: [
            'org', 'sale-type'
          ],
          section: 'sales',
          orgTypes: [1]
      },
    };

    FILTER_CONFIG: { [id: string]: Filter } = {
        'org': {
            type: 'org',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'vehicle': {
            type: 'vehicle',
            selected: [],
            removable: false,
            lockable: true,
            locked: true
        },
        'audience-segment': {
            type: 'audience-segment',
            available: [],
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'journey-segment': {
            type: 'journey-segment',
            available: [],
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'brand': {
            type: 'brand',
            selected: [
                { value: 1, display: 'Chevrolet' },
                { value: 2, display: 'Buick' },
                { value: 3, display: 'GMC' },
                { value: 4, display: 'Cadilac' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'campaign-type': {
            type: 'campaign-type',
            available: [],
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'channel': {
            type: 'channel',
            available: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
            selected: [
                { value: 1, display: 'Brand' },
                { value: 2, display: 'Paid Search' },
                { value: 3, display: 'Organic Search' },
                { value: 4, display: 'Paid Display' },
                { value: 5, display: 'Paid Video' },
                { value: 6, display: 'Paid Email' },
                { value: 7, display: 'Organic Email' },
                { value: 8, display: 'Paid Social' },
                { value: 9, display: 'Organic Social' },
                { value: 10, display: 'Third Party Sites' },
                { value: 11, display: 'Typed/Bookmarked' },
                { value: 12, display: 'Discovery' },
                { value: 13, display: 'Local' },
                { value: 14, display: 'Shopping' },
                { value: 15, display: 'Performance Max' },
                { value: 16, display: 'Online Video' },
                { value: 17, display: 'Connected TV' },
                { value: 18, display: 'Over the Top' },
                { value: 19, display: 'Vehicle Listing' },
                { value: 20, display: 'Streaming Audio' },
                { value: 21, display: 'Other' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'chat-provider': {
            type: 'chat-provider',
            available: [],
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'digad-provider': {
            type: 'digad-provider',
            available: [],
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'device-type': {
            type: 'device-type',
            available: [1,2,3,5],
            selected: [
                { value: 1, display: 'Desktop' },
                { value: 2, display: 'Tablet' },
                { value: 3, display: 'Phone' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'lead-type': {
            type: 'lead-type',
            selected: [{ value: 1, display: 'New Vehicle' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'sale-type': {
            type: 'sale-type',
            selected: [{ value: 0, display: 'New' }],
            removable: false,
            lockable: true,
            locked: false
        },
        'source-type': {
            type: 'source-type',
            selected: [
                { value: 1, display: 'Brand' },
                { value: 3, display: 'Dealer Website' },
                { value: 5, display: 'Event' }
            ],
            removable: false,
            lockable: true,
            locked: false
        },
        'trade-in-provider': {
            type: 'trade-in-provider',
            available: [],
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'lma': {
            type: 'lma',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'lma-region': {
            type: 'lma-region',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'strategy': {
            type: 'strategy',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'tactic': {
            type: 'tactic',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-make': {
            type: 'vehicle-make',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-makes': {
            type: 'vehicle-makes',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-class': {
            type: 'vehicle-class',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'vehicle-model': {
            type: 'vehicle-model',
            selected: [],
            removable: true,
            lockable: true,
            locked: false
        },
        'website-provider': {
            type: 'website-provider',
            available: [1, 2, 3, 4, 15],
            selected: [
                { value: 2, display: 'Dealer Inspire' },
                { value: 1, display: 'Dealer.com' },
                { value: 3, display: 'DealerOn' },
                { value: 4, display: 'Ansira' },
                { value: 15, display: 'Other' }
            ],
            removable: true,
            lockable: true,
            locked: false
        },
        'website-provider-scorecard': {
            type: 'website-metric-type',
            available: [57, 11, 14, 2],
            selected: [
                { value: 57, display: 'Dealer Enrollments' },
            ],
            removable: false,
            lockable: true,
            locked: false
        },

    };
}
